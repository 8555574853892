"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SetTabataCtrl = void 0;
const alarmclock_countdown_timer_1 = require("@src/shared/alarmclock~countdown~timer");
const utils_1 = require("@src/shared/utils");
class SetTabataCtrl extends alarmclock_countdown_timer_1.BaseSetCtrl {
    constructor($scope, $locale, $timeout, $location, $element, ConfigService, SoundService, UploadDataUrl) {
        super($scope, $locale, $timeout, $location, $element, ConfigService, SoundService, UploadDataUrl);
        this.$scope = $scope;
        this.$locale = $locale;
        this.$timeout = $timeout;
        this.$location = $location;
        this.$element = $element;
        this.ConfigService = ConfigService;
        this.SoundService = SoundService;
        this.UploadDataUrl = UploadDataUrl;
        this.sounds = this.SoundService.getOptions();
        this.played = false;
        this.timerData = {
            step: 'prepare',
            prepare: (0, utils_1.rangeInitial)(this.$modalScope.data.prepare, 10, 0, 300),
            work: (0, utils_1.rangeInitial)(this.$modalScope.data.work, 20, 1, 300),
            rest: (0, utils_1.rangeInitial)(this.$modalScope.data.rest, 10, 1, 300),
            restTabata: (0, utils_1.rangeInitial)(this.$modalScope.data.restTabata, 60, 1, 300),
            cycle: (0, utils_1.rangeInitial)(this.$modalScope.data.cycle, 8, 1, 20),
            tabata: (0, utils_1.rangeInitial)(this.$modalScope.data.tabata, 1, 1, 20),
            soundRepeat: false,
            soundVolume: (0, utils_1.rangeInitial)(this.$modalScope.data.soundVolume, 75, 0, 100, 25),
            soundMessage: this.$modalScope.data.soundMessage,
            // sound: this.$modalScope.data.sound,
        };
    }
    $onInit() {
        super.$onInit();
        console.log(this.$modalScope);
        this.$scope.$watch('$ctrl.timerData.soundVolume', (soundVolume, oldSoundVolume) => {
            if (soundVolume != oldSoundVolume) {
                const soundInstances = this.SoundService.soundbox.instances;
                this.SoundService.stop_all();
                this.SoundService.playDuration(1000, '~Beep');
                if (soundInstances.length) {
                    soundInstances[soundInstances.length - 1].volume = soundVolume / 100;
                }
            }
        });
        this.$scope.$watch('$ctrl.timerData', (timerData, oldTimerData) => {
            // if (timerData.sound?.label != oldTimerData.sound?.label) {
            //     if (this.played) {
            //         this.SoundService.stop_all();
            //         this.$timeout(() => {
            //             this.play()
            //         }, 100)
            //     } else {
            //         // this.played = true;
            //     }
            // }
            Object.assign(this.$modalScope.data, timerData);
        }, true);
    }
    sub(value, min, step) {
        if (typeof this.timerData[value] === 'number') {
            this.timerData[value] -= (this.timerData[value] > min ? step : 0);
        }
        else {
            this.timerData[value] = min;
        }
    }
    add(value, max, step) {
        if (typeof this.timerData[value] === 'number') {
            this.timerData[value] += (this.timerData[value] < max ? step : 0);
        }
        else {
            this.timerData[value] = max;
        }
    }
    // play(): void {
    //     this.played = true;
    //     this.SoundService.play(
    //         this.timerData.sound.label,
    //         this.timerData.soundRepeat,
    //         this.timerData.soundVolume / 100,
    //     ).finally(() => {
    //         this.$scope.$apply(() => {
    //             this.played = false;
    //         })
    //     });
    // }
    setPreset(prepare, work, rest, cycle, tabata) {
        this.timerData.prepare = prepare;
        this.timerData.work = work;
        this.timerData.rest = rest;
        this.timerData.cycle = cycle;
        if (tabata != undefined)
            this.timerData.tabata = tabata;
    }
    debug(prepare, work, rest, cycle, tabata) {
        this.setPreset(prepare, work, rest, cycle, tabata);
        Object.assign(this.$modalScope.data, this.timerData);
        const instanceKey = this.$modalScope.$random ? this.$modalScope.id + '_' + this.$modalScope.$random : this.$modalScope.id;
        this.modalInstance.close(instanceKey, 'start');
    }
    close(result) {
        const _super = Object.create(null, {
            close: { get: () => super.close }
        });
        return __awaiter(this, void 0, void 0, function* () {
            if (result == 'start') {
                if (this.tabataForm) {
                    if (this.tabataForm.$valid) {
                        return _super.close.call(this, 'start');
                    }
                    else {
                        return Promise.resolve();
                    }
                }
            }
            return _super.close.call(this, result);
        });
    }
}
exports.SetTabataCtrl = SetTabataCtrl;
SetTabataCtrl.$inject = [
    '$scope',
    '$locale',
    '$timeout',
    '$location',
    '$element',
    'ConfigService',
    'SoundService',
    'UploadDataUrl'
];
